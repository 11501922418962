import { IEnvironment } from 'environments/environment.interface';

export const environment: IEnvironment = {
  production: false,
  environment: 'test',
  webapiEndpoint: 'https://webapi-tst.snelstart.nl',
  desktopApiEndpoint: 'https://desktopapi-tst.azurewebsites.net',
  accountService: 'https://accountService-tst.snelstart.nl',
  articlesGatewayApiEndpoint:
    'https://snelstart-product-article-tst.azurewebsites.net',
  bookkeepingApiEndpoint:
    'https://snelstart-operations-bookkeeping-tst.azurewebsites.net',
  bookkeepingV2ApiEndpoint: 'https://api-tst.snelstart.nl/product/bookkeeping',
  mailboxSuffix: '@test.boekhouding.nl',
  miscDocumentsApiEndpoint:
    'https://snelstart-product-miscdocument-tst.azurewebsites.net',
  userManagementApiEndpoint:
    'https://snelstart-operations-usermanagement-tst.azurewebsites.net',
  identityGatewayApiEndpoint:
    'https://snelstart-operations-identitygateway-tst.azurewebsites.net',
  identityStoreDomain: 'login-tst.snelstart.nl',
  identityStoreClientId: 'vb3rI3au0Aba355I462DLKQbPH6eTIHX',
  identityStoreAudience: 'https://webapi-tst.snelstart.nl',
  applicationInsightsInstrumentationKey: 'd4838092-000c-4a3a-a349-e60532c894d9',
  allowedIFrameOrigin: 'https://web-tst.snelstart.nl',
  flowYourMoneyUrl: 'https://snelstart-flowyourmoney-tst.azureedge.net',
  funnelyticsId: '171773e7-48ad-467e-b18a-af34ea445937',
  rapportenUrl: 'https://rapporten-tst.snelstart.nl/v1/snelstart/connect',
  bankierenUrl: 'https://bankieren-tst.snelstart.nl',
  accountWebShell: 'https://account-tst.snelstart.nl',
  remotes: {
    product_articles: 'https://ssparticlestst.z6.web.core.windows.net',
    product_customization: 'http://localhost:4203',
  },
  bokkzApiBaseUrl: 'https://test.leukermakkelijker.nl',
  administrationManagementApiEndpoint:
    'https://api-tst.snelstart.nl/operations/administration-management',
};
